
export const navigation = [
  {
    text: 'Tablero',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Catalogos',
    icon: 'folder',
    items: [
      {
        text: 'Tipos de Platos',
        path: '/catalogos/tipoplato',
        icon: 'fas fa-utensils'

      },
      {
        text: 'Platos',
        path: '/catalogos/platos',
        icon: 'fas fa-hamburger'
      },
      {
        text: 'plantas',
        path: '/catalogos/plantas',
        icon: 'fas fa-warehouse'
      },


    ]
  },
  {
    text: 'Menu - Diario',
    icon: 'money',
    items: [
      {
        text: 'Diario',
        path: '/catalogos/menu-diario',
        icon: 'fas fa-list-alt'
      },

    ]
  }
];
