

export async function encrypt(text: string, password: string): Promise<string> {
  const encoder = new TextEncoder();
  const key = await window.crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );
  const salt = encoder.encode("salt");
  const iterations = 100000;
  const keyAlgorithm = {
    name: "PBKDF2",
    salt: salt,
    iterations: iterations,
    hash: { name: "SHA-256" },
  };
  const keyDerived = await window.crypto.subtle.deriveKey(
    keyAlgorithm,
    key,
    { name: "AES-CBC", length: 256 },
    false,
    ["encrypt"]
  );
  const iv = crypto.getRandomValues(new Uint8Array(16));
  const encrypted = await window.crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    keyDerived,
    encoder.encode(text)
  );
  const encryptedArray = new Uint8Array(encrypted);
  const resultArray = new Uint8Array(iv.length + encryptedArray.length);
  resultArray.set(iv);
  resultArray.set(encryptedArray, iv.length);
  return btoa(String.fromCharCode(...resultArray));
}

export async function decrypt(encryptedText: string, password: string): Promise<string> {
  const key = await window.crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );
  const salt = new TextEncoder().encode("salt");
  const iterations = 100000;
  const keyAlgorithm = {
    name: "PBKDF2",
    salt: salt,
    iterations: iterations,
    hash: { name: "SHA-256" },
  };
  const keyDerived = await window.crypto.subtle.deriveKey(
    keyAlgorithm,
    key,
    { name: "AES-CBC", length: 256 },
    false,
    ["decrypt"]
  );
  const bytes = new Uint8Array(Array.from(atob(encryptedText), c => c.charCodeAt(0)));
  const iv = bytes.slice(0, 16);
  const encrypted = bytes.slice(16);
  const decrypted = await window.crypto.subtle.decrypt(
    { name: "AES-CBC", iv: iv },
    keyDerived,
    encrypted
  );
  return new TextDecoder().decode(decrypted);
}






