
<div class="my-login-page" >
  <section class="h-100">
		<div class="container h-100">
			<div class="row justify-content-md-center h-100">
				<div class="card-wrapper">
					<div class="brand">
						<img [src]="logo" alt="logo">
					</div>
					<div class="card fat">
						<div class="card-body">
							<h4 class="card-title">Login</h4>
              <form class="my-login-validation" (submit)="onSubmit($event)">

                <dx-form [formData]="formData" [disabled]="loading">

                  <dxi-item dataField="email" editorType="dxTextBox" cssClass="form-group"
                    [editorOptions]="{ stylingMode: 'filled', placeholder: 'Usuario' }">
                    <dxi-validation-rule type="required" message="Nombre de usuario obligatorio"></dxi-validation-rule>
                    <dxo-label [visible]="true"></dxo-label>
                  </dxi-item>

                  <dxi-item dataField="password" editorType="dxTextBox"
                    [editorOptions]="{ stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' }">
                    <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
                    <dxo-label [visible]="true"></dxo-label>
                  </dxi-item>

                  <dxi-item dataField="rememberMe" editorType="dxCheckBox"
                    [editorOptions]="{ text: 'Recordarme', elementAttr: { class: 'form-text' } }">
                    <dxo-label [visible]="false"></dxo-label>
                  </dxi-item>

                  <dxi-item itemType="button">
                    <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
                    </dxo-button-options>
                  </dxi-item>

                  <dxi-item>
                    <div class="link">
                      <a routerLink="/reset-password">Retornar Clave?</a>
                    </div>
                  </dxi-item>

                  <dxi-item itemType="button">
                    <dxo-button-options text="Create an account" width="100%" [onClick]="onCreateAccountClick"></dxo-button-options>
                  </dxi-item>

                  <ng-container *dxTemplate="let item of 'signInTemplate'">
                    <div>
                      <span class="dx-button-text">
                        <ng-container *ngIf="loading; else notLoading">
                          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
                        </ng-container>
                        <ng-template #notLoading>Iniciar Sesión</ng-template>
                      </span>
                    </div>
                  </ng-container>

                </dx-form>
              </form>
            </div>
					</div>
					<div class="footer">
						Copyright &copy; 2024 &mdash; Arteagasoft S de RL
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

