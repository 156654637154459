import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from '../../services';
import { encrypt } from '../../utils/cypher';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loading = false;
  formData: any = {};
  pwd: string = 'koiv$kEroKy8V14Pqs1jLg^Iw6jpb82E';
  constructor(private authService: AuthService, private router: Router) { }

  logo: string = '../../../../assets/images/logo.jpg';

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    let preLogin: any = {
      UserName: email,
      Password: password,
      AppId: environment.APPID,
      EmpresaKey: '',
      AppKey: ''
    }
    this.loading = true;

    // vamos a encriptar para enviar
    encrypt(preLogin.Password, this.pwd).then((rr) => {
      preLogin.Password = rr;

      console.log('Encriptado', rr);

      const pre = this.authService
        .addElemento2(preLogin, 'Login/PreLogin')
        .subscribe(async resp => {
          console.log('PreLogin', resp, preLogin);
          if(resp.Data) {
            if(Array.isArray(resp.Data)){
              if(resp.Data.length > 0) {
                 // Vamos a encriptar los valores de la empresa
                encrypt(resp.Data[0].EmpresaKey, this.pwd).then((cia) => {
                  preLogin.EmpresaKey = cia;

                  // Encriptamos la App Id
                  encrypt(resp.Data[0].AppKey, this.pwd).then(async app => {
                    preLogin.AppKey = app;

                    // Ahora que ya tenemos todo procedemos ha gestionar el login

                    const result1 = this.authService.logIn(preLogin)
                      .subscribe(result => {
                            //console.log(result);
                          if (!result.isOk) {
                            this.loading = false;
                            console.log('Error')
                            notify(result.message, 'error', 10000);
                          }
                          // todo: VAMOS A buscar el perfil del usuario
                          const auth = result.data;
                          // vamos a sacar el perfil del usuario
                          this.authService.getPerfilUsuario(auth)
                            .subscribe(rr=> {
                              localStorage.setItem('Perfil', JSON.stringify(rr.Data));
                              console.log('Resultado de AUTH: ', auth)
                              localStorage.setItem("id_token", auth.TokenId);
                              localStorage.setItem("Sesion", JSON.stringify(auth.SesionUsuario));
                              localStorage.setItem("configuracionExtra", JSON.stringify(auth.UsuarioConf));
                              localStorage.setItem("roles", JSON.stringify(auth.ListaRoles));
                              localStorage.setItem("UserName", JSON.stringify(auth.UserName));
                              localStorage.setItem("FechaVence", JSON.stringify(auth.FechaVence));
                              localStorage.setItem("TodasEmpresas", JSON.stringify(auth.TodasEmpresas));
                              localStorage.setItem("Ubicaciones", JSON.stringify(auth.Ubicaciones));
                              localStorage.setItem("EmpresaId", auth.EmpresaId);
                              localStorage.setItem("acHora", JSON.stringify({ hora: new Date().toTimeString(), valor: 0 }));
                              localStorage.setItem('MenuApp', JSON.stringify(auth.MenuApp));
                              localStorage.setItem("currentUser", JSON.stringify(auth.UserName));
                              window.location.reload();
                          });
                          this.router.navigate(["/home"]);
                        });
                    // ------------------------------------------------
                  })
                })
              } else {
                this.loading = false;
                notify('Usuario o contraseña incorrectos', 'error', 2000);
                Swal.fire({
                  title: 'Error!',
                  text: 'Usuario o contraseña incorrectos',
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                })
                return;
              }
            } else {
              this.loading = false;
                notify('Usuario o contraseña incorrectos', 'error', 2000);
                Swal.fire({
                  title: 'Error!',
                  text: 'Usuario o contraseña incorrectos',
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                })
                return;
            }

          } else {
            this.loading = false;
                notify('Usuario o contraseña incorrectos', 'error', 2000);
                Swal.fire({
                  title: 'Error!',
                  text: 'Usuario o contraseña incorrectos',
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                })
                return;
          }


        });

    })



  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }


