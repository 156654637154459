


export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://cafeteria.api.arteagasoft.com',
  API_ENDPOINT: 'https://cafeteria.api.arteagasoft.com/api/cafeteria/v1.0',
  API_SECURITY: "https://apicerbere.esaywassap.com",
  APPID: "f7be9749-6f5a-4e7b-84d6-872c4b811501",
  VERSION: "22.1.1.0"
  // apiUrl: 'http://localhost:4000'
};


// f7be9749-6f5a-4e7b-84d6-872c4b811501
