import { AfterContentInit, AfterViewInit, Component, OnChanges } from '@angular/core';
//import { CardAnalyticsComponent } from 'src/app/pages/library/card-analytics/card-analytics.component'


@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ]
})

export class HomeComponent implements AfterViewInit, AfterContentInit, OnChanges {

  isLoading: boolean = true;


  constructor() {

  }
  ngAfterContentInit(): void {

  }
  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {

  }


}
//
