import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
export const BYPASS_TOKEN = new HttpContextToken(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private AUTH_HEADER2 = "Authorization";
  private token = '';
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor() {
    let xToken = JSON.parse(localStorage.getItem('loginInfo')!);
    if(xToken) {
      this.token = xToken.TokenId;
    } else this.token = '';
  }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let xToken = JSON.parse(localStorage.getItem('loginInfo')!);
    if(xToken) {
      this.token = xToken.TokenId;
    } else this.token = '';
    if (req.context.get(BYPASS_TOKEN) !== true) {
      if (!req.headers.has('Content-Type')) {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
        });
      }
      req = req.clone({
        headers: req.headers.set(this.AUTH_HEADER2, `Bearer ${this.token!}`)
      });
    }
    if (req.context.get(BYPASS_TOKEN) === true) {
      //console.log('Peticion fuera del contexto seguro...');
      return next.handle(req)
    }
    return next.handle(req);
  }
}
