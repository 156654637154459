import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable, of, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BYPASS_TOKEN } from '../interceptores/JwtIntercepto';
import { tokenGenerador } from '../utils/interfaces';

export interface IUser {
  id?: number;
  email?: string;
  avatarUrl?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  role?: Role;
  token?: string;
  estado?: number;
  datos?: any;
}

export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User'
}

const defaultPath = '/';
const defaultUser = {
  email: '',
  avatarUrl: ''
};

@Injectable()
export class AuthService {
  private _user: IUser | null = defaultUser;

  constructor(private router: Router,
    private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this._http.get("https://api.ipify.org/?format=json", {
      context: new HttpContext().set(BYPASS_TOKEN, true),
    })
      .subscribe((resp: any) => {
        //console.log('Cliente remoto: ' + resp.ip);
        localStorage.setItem('public_IP', resp.ip);
        this.publicIP = resp.ip;
      });
  }



  get loggedIn(): boolean {

    return this.authenticated();
  }

  get getToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token') || undefined;
    }
    return undefined;
  }

  get Planta() {
    if(localStorage.getItem('configuracionExtra')) {
      const confExtras = JSON.parse(localStorage.getItem('configuracionExtra'));
      const planta = confExtras.find((element: any) => element.Propiedad === 'Planta');
      if(planta) {
        return planta.Valor;
      } else return undefined;
    } else return undefined;
  }

  /**
   * Manejo de errores
   * @param error Objeto Eror
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }


  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  public currentUser: Observable<IUser>;

  //private
  private currentUserSubject: BehaviorSubject<IUser>;
  publicIP: string = ''

  logIn(data: any): Observable<any> {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json")
    /*.set(
      'XZYApiKey', '6d18ce56-090a-498e-8134-e515e8f63675'
    );*/
    const userLogin = { ...data, Dispositivo: this.publicIP, EsMobil: false }
    //console.log(userLogin)
    try {
      return this._http
        .post<any>(
          `${environment.API_SECURITY}/api/v2/Login/Login`,
          JSON.stringify(userLogin), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
          })
        }).pipe(
          map((result1) => {
            let result: tokenGenerador = result1.Data;
            localStorage.setItem("loginInfo", JSON.stringify(result))
            return {
              isOk: true,
              data: result
            };
          })
        );
    }
    catch {
      const obj: any = {
        isOk: false,
        message: "Authentication failed"
      };
      return obj;
    }
  }

  async sleep(fn, ...args) {
    await timeout(3000);
    return fn(...args);
  }

  /**
   * Retorna el usuario en curso
   * @returns
   */
  async getUser() {
    try {
      this.authenticated();
      if (localStorage.getItem('UserName')) {
        let auser: any = {
          email: '',
          firstName: '',
          id: 0,
          lastName: '',
          token: '',
          avatar: '',
          password: ''
        };
        const storage = localStorage.getItem('UserName');
        const token = localStorage.getItem('id_token');
        const perfil = JSON.parse(localStorage.getItem('Perfil'));

        auser.email = perfil.Email;
        auser.firstName = perfil.NombreCompleto;
        auser.id = 1;
        auser.lastName = "";
        auser.token = token;
        auser.avatar = perfil.PathFoto;
        auser.password = "";

        return {
          isOk: true,
          data: auser
        };
      }
      return {
        isOk: false,
        data: null
      }
    }
    catch {
      return {
        isOk: false,
        data: null
      };
    }
  }

  getPerfilUsuario(sesionData: tokenGenerador ): Observable<any> {
    //console.log('SesionData', sesionData);
      if (sesionData.TokenId) {
        const session = sesionData.SesionUsuario;
        return this._http
          .get(`${environment.API_SECURITY}/api/v2/Usuarios/getperfil/${session.UsuarioId}`)
          .pipe(
            retry(3),
            catchError(this.handleError),
            map((resp: any) => {
              let auser: any = {
                email: '',
                firstName: '',
                id: 0,
                lastName: '',
                token: '',
                avatar: '',
                password: ''
              };
              const perfil = resp.Data;
              auser.email = perfil.Email;
              auser.firstName = perfil.NombreCompleto;
              auser.id = 1;
              auser.lastName = "";
              auser.token = sesionData.TokenId;
              auser.avatar = perfil.PathFoto.replace(/\\/g, "/");
              auser.avatar = environment.API_SECURITY + '/' + perfil.PathFoto.replace(/\/\//g, "/");
              auser.password = "";
              localStorage.setItem('currentUser', JSON.stringify(auser));
              this.currentUserSubject.next(auser);
              resp.Data.PathFoto = resp.Data.PathFoto.replace(/\\/g, "");
              resp.Data.PathFoto = environment.API_SECURITY + '/' + resp.Data.PathFoto.replace(/\/\//g, '/');
              localStorage.setItem('fotoPerfil', resp.Data.PathFoto);
              return resp;
            })
          );
      }

    return of(null); // Add this line to return an observable value when the conditions are not met
  }

  /**
* Permite agregar un objeto mediante el metodo Post
* @param _app Elemento a agregar
* @param path Ruta del API
*/
  addElemento2(_app: any, path: string): Observable<any> {
    return this._http.post(`${environment.API_SECURITY}/api/v2/${path}?lap=${Date.now()}`,
      _app)
      .pipe(retry(3), catchError(this.handleError),
        map((resp: any) => {
          return resp;
        })
      );
  }

  async createAccount(email: string, password: string) {
    try {
      // Send request

      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  }

  async resetPassword(email: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  }

  getTokenId() {
    if(this.authenticated()) {
      return localStorage.getItem('id_token');
    } else return undefined
  }

  authenticated() {
    let estado: boolean = false;
    try {
      //console.log(JSON.parse(localStorage.getItem('Sesion')), (new Date().getTime()))
      if(localStorage.getItem('loginInfo')){
        if(localStorage.getItem('Sesion')) {
          let sesion: any = JSON.parse(localStorage.getItem('Sesion'));
          if(new Date(sesion.FechaVence) < new Date()) {
            estado = false;
            this.logout();
            return estado;
          }
          else {
            estado = true;
            return estado;
          }
        } else return false;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;

    }
  }

  /**
   * Retorna el ID de la empresa que esta seleccionada al hacer login
   */
  get empresaId(): string {
    if(localStorage.getItem('EmpresaId')) {
      return localStorage.getItem('EmpresaId');
    } else return undefined;
  }

  /**
   * Retorna la ruta del perfil del usuario que realizo login
   */
  get fotoPerfil(): string {
    if(localStorage.getItem('fotoPerfil')) {
      return localStorage.getItem('fotoPerfil');
    } else return undefined;
  }

  /**
   * Retorna el listado de roles del usuario
   */
  get listRoles(): any[] {
    if(localStorage.getItem('roles')) {
      return JSON.parse(localStorage.getItem('roles'));
    } else return undefined;
  }

  async logout() {
    this._user = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem("id_token");
    localStorage.removeItem("Sesion");
    localStorage.removeItem("configuracionExtra");
    localStorage.removeItem("roles");
    localStorage.removeItem("UserName");
    localStorage.removeItem("FechaVence");
    localStorage.removeItem("TodasEmpresas");
    localStorage.removeItem("Ubicaciones");
    localStorage.removeItem("EmpresaId");
    localStorage.removeItem("acHora");
    localStorage.removeItem('MenuApp');
    localStorage.removeItem("currentUser");
    localStorage.removeItem('Perfil')
    localStorage.removeItem('loginInfo');
    this.router.navigate(['/login-form']);

  }
}


@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
